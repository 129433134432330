import React from 'react';

import {
    BooleanField,
    DateField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    ImageField,
    ImageInput,
    SimpleList,
    NumberInput,
    List,
    ReferenceArrayInput,
    ArrayField,
    SimpleForm,
    TextField,
    TextInput, NumberField
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const CoursesList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <DateField source="created"/>
            <BooleanField source="active"/>
            <ImageField source="media.image.url" label="Image"/>
            <TextField source="title"/>
            <NumberField source="orderIndex"/>
            <TextField multiline="true" source="description"/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const CourseEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="title"/>
            <TextInput source="description" multiline="true" fullWidth/>
            <NumberInput source="orderIndex"/>
            <NumberInput source="calculatedSessionLength_s"/>

            <BooleanInput source="active"/>
            <BooleanInput source="orderLocked"/>
            <ImageField source="media.image.url" label="Current Image"/>

            <ImageInput source="picture" label="Upload new image" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}
            >
                <ImageField source="src" title="title" label="New Image"/>
            </ImageInput>


            <ArrayField source="sessions" fullWidth>
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => record.description}
                />
            </ArrayField>
        </SimpleForm>
    </Edit>
);

export const CourseCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="title"/>
            <TextInput source="description"/>
            <NumberInput source="orderIndex"/>
            <BooleanInput source="active"/>
            <BooleanInput source="orderLocked"/>

            <ImageInput source="picture" label="Image To Upload" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}

            >
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);
